// IMPORTS
@import (once) "/node_modules/nouislider/dist/nouislider.css";

@import (reference) "../settings.less";

// SIDEBAR
.sidebar {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

// SIDEBAR PRODUCTS
.sidebar-products-wrapper {
    padding: 24px;
    border-radius: 8px;
    border: 1px solid @border-color;
    position: relative;
    --swiper-navigation-sides-offset: -16px;

    .headline {
        font-size: 18px;
        margin-bottom: 16px;
    }

    .product {

        .product-name {
            -webkit-line-clamp: 3;
            height: ~"calc(3 * (var(--fontSize) * var(--lineHeight)))";
        }

        &:after {
            display: none !important;
        }
    }

    .slider-button2 {
        top: 171px;
    }
}

// SIDEBAR BANNERS
.sidebar-banners-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.sidebar-banner {
    border-radius: 8px;
    width: 100%;
    aspect-ratio: ~"260/140";
    overflow: hidden;
    position: relative;

    img {
        .img-crop();
    }

    b {
        position: absolute;
        bottom: 12px;
        left: 0;
        right: 0;
        text-align: center;
        margin: 0 auto;
        width: fit-content;
        max-width: 90%;
        background: rgba(23,23,23,0.8);
        padding: 8px 38px;
        color: white;
        font-weight: 700;
        border-radius: 4px;
    }
}

// TREE
@media (min-width: 1280px) {
    .cms-rwd,
    .tree-header {
        display: none;
    }

    #tree {

        > nav {
            background-color: white;
            padding: 24px 24px 12px 24px;
            border-radius: 8px;
            border: 1px solid @border-color;

            &:before {
                content: 'Kategorie';
                width: 100%;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 18px;
                line-height: 1.3;
            }

            > ul {
                padding-top: 4px;
            }
        }

        li {
            position: relative;

            &.downarrow {

                > button:after {
                    transform: scaleY(-1);
                }

                > ul {
                    display: block;
                }
            }

            ul {
                display: none;
            }
        }

        li.t0 {

            > a {
                display: block;
                font-weight: 700;
                padding: 12px 0;
                padding-right: 36px;
                border-bottom: 1px solid @border-color;
            }

            > ul {
                border-bottom: 1px solid @border-color;
            }

            &:last-child {

                > a {
                    border-bottom: none;
                }

                > ul {
                    border-top: 1px solid @border-color;
                    border-bottom: none;
                }
            }
        }

        a {
            position: relative;
            color: @header-color;

            &:hover {
                color: @main-color;
            }
        }

        li:not(.t0) {

            &:first-child a {
                padding-top: 12px;
            }

            a {
                padding: 6px 0;
                display: block;
            }

            &:last-child a {
                padding-bottom: 12px;
            }
        }

        li.t1 > a {
        }

        li.t2 > a {
            padding-left: 16px;
        }

        li.t3 > a {
            padding-left: 24px;
        }

        li.selected > a {
            color: @main-color;
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 36px;
            height: 49px;
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: @header-color;

            &:after {
                content: "\e91a";
                font-family: icomoon;
            }
        }

        small {
            /*font-size: 12px;
            color: @font-color;
            position: absolute;
            top: 0;
            right: 0;
            font-weight: normal;
            height: 100%;
            width: 40px;
            display: flex;
            align-items: center;*/
            display: none;
        }
    }
}

// FILTER
.close-filter,
.close-filter-bottom {
    display: none;
}

.filter-trigger {
    border: 1px solid @border-color;
    color: @font-color;
    min-width: 258px;
    height: 48px;
    text-align: left;
    border-radius: 4px;
    position: relative;
    padding: 0 20px;
    z-index: 1;
    display: none;

    &:before {
        content: 'Filtrowanie';
        background-color: @total-color;
        position: absolute;
        top: -10px;
        left: 10px;
        font-size: 12px;
        font-weight: 700;
        color: @header-color;
        padding: 0 8px;
    }

    svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
    }

    &:hover {
        border-color: @header-color;
    }
}

// STANDARD FILTER
body[data-canvas-filter] {

    &:has(#filter .gs-filtr-container) {

        .navigation-wrapper {
            display: flex;
        }

        .filter-trigger {
            display: block;
        }
    }
}

.filter-sorter-wrapper {
    border: 4px solid @border-color;
    border-top: 0;
    border-bottom-width: 1px;
    padding: 24px 17px;
    
    .sorter {

        &:before {
            display: none;
        }
    }

    .sorter-button {
        min-width: 0;
        width: 100%;
    }
}

@media (min-width: 1280px) {
    body:not([data-canvas-filter]) {

        .filter-trigger {
            display: none;
        }

        #filter {
            border-radius: 8px;
            padding: 24px;
            overflow: hidden;
            display: none;
            flex-direction: column;
            gap: 24px;
            border: 1px solid @border-color;

            &:has(.gs-filtr-container) {
                display: flex;
            }

            > h3 {
                display: block;
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 700;
                line-height: 1.3;
                margin-bottom: -8px;
            }

            .gs-filtr-container {

                &:last-of-type {
                    border-bottom-width: 4px;
                }

                > strong {
                    display: block;
                    font-weight: 700;
                    margin-bottom: 8px;
                    color: @header-color;

                    span {
                        display: none;
                    }

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }

                input[type="checkbox"] {
                    display: none;
                }

                input:checked + label {

                    &:after {
                        transform: scale(1);
                    }
                }

                label {
                    display: block;
                    cursor: pointer;
                    user-select: none;
                    padding: 4px 0;
                    padding-left: 34px;
                    position: relative;
                    color: @font-color;
                    font-size: 14px;

                    &:hover {
                        text-decoration: underline;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 5px;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        transition: border-radius .1s ease-in-out;
                        border: 1px solid @border-color;
                        border-radius: 2px;
                    }

                    &:after {
                        content: '\e801';
                        font-family: icomoon;
                        position: absolute;
                        top: 5px;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        color: @header-color;
                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: transform .2s ease-in-out;
                        transform: scale(0);
                    }
                }

                &.colors {

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 5px;
                    }

                    li {
                        display: block;
                    }

                    label {
                        font-size: 0;
                        padding: 0;
                        width: 32px;
                        height: 32px;
                        border: 2px solid @border-color;
                        outline: 5px solid @total-color;
                        outline-offset: -6px;
                        border-radius: 2px;

                        &:before,
                        &:after {
                            display: none;
                        }

                        &:hover {
                            border-color: @header-color;
                        }
                    }

                    input:checked + label {
                        border-color: @header-color;
                        outline-width: 5px;
                    }
                }

                &.producers {

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 4px;
                    }

                    li {
                        display: block;
                    }

                    label {
                        padding: 3px 15px;
                        background-color: @total-color;
                        border: 1px solid @border-color;
                        font-size: 14px;
                        border-radius: 2px;

                        &:before,
                        &:after {
                            display: none;
                        }

                        &:hover {
                            border-color: @header-color;
                            text-decoration: none;
                        }
                    }

                    input:checked + label {
                        border-color: @header-color;
                        background-color: @header-color;
                        color: @total-color;
                    }
                }
            }
        }
    }

    #filter .gs-filtr-container {

        &.no-filters-found {

            &:after {
                content: 'brak filtrów...';
                color: @error-color;
                display: block;
            }
        }

        > input {
            height: 30px;
            margin-bottom: 5px;
            padding: 0 10px;
            font-size: 13px;
        }

        label {

            &.hide {
                display: none !important;
            }
        }
    }
}

.price-filtering {

    .noUi-target {
        box-shadow: none;
    }

    .noUi-horizontal .noUi-handle {
        box-shadow: none;
        cursor: grab;
        width: 16px;
        height: 16px;
        background-color: @hover-color;
        border-radius: 3px;
        border: 0;

        &:before,
        &:after {
            display: none;
        }

        &.noUi-active {
            cursor: grabbing;
        }

        &.noUi-handle-upper {
            right: 0;
        }
    }
}

#price-filter-handles {
    margin: 18px auto 17px;
    height: 4px;
    background-color: @border-color;
    border: 0;
    border-radius: 0;
}

#price-filter-amounts {
    display: flex;
    justify-content: space-between;

    label,
    i {
        font-style: normal;
        line-height: 33px;
        color: @font-color;
    }

    label {
        padding: 0 !important;
    }

    label:before {
        display: none;
    }

    input {
        display: block;
        height: 33px;
        border: 1px solid @border-color;
        background: transparent;
        text-align: center;
        padding: 0;
        text-align: center;
        width: 68px;
    }
}

// LISTING WRAPPER
.listing-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 48px 24px;
    margin-top: 17px;

    > *:not(.category-headline, .navigation-wrapper, .filter-trigger) {
        width: 100%;
    }
}

.category-headline {
    margin-bottom: 0;
}

// CATEGORY DESCRIPTION
.category-description {
    order: 1;
    text-align: center;
    font-size: 16px;
    margin-top: 40px;
}

// NAVIGATION
.navigation-wrapper {
    display: none;
    gap: 24px;
    margin-left: auto;

    &:has(.dropdown-picker) {
        display: flex;
    }
}
.listing-wrapper:has(#pills-container .single-pill) {
    navigation-wrapper {
        display: flex;
    }
}

.listing-wrapper:has(.products-list:empty) {

    .navigation-wrapper {
        display: none;
    }

    .dropdown-picker {
        display: none;
    }
}

#pills-container {

    &:empty {
        display: none;
    }

    &:not(:empty) {
        margin-top: -40px;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;
    }
}

// DROPDOWN PICKER
.dropdown-picker {
    position: relative;
    display: flex;
    position: relative;
    z-index: 10;

    &.show-dropdown {

        .dropdown-button:after {
            transform: scaleY(-1);
        }

        .dropdown-listing {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }

    &:before {
        background-color: @total-color;
        position: absolute;
        top: -9px;
        left: 17px;
        font-size: 12px;
        font-weight: 700;
        color: @header-color;
        padding: 0 4px;
        z-index: 11;
        pointer-events: none;
        text-transform: uppercase;
    }

    &[data-dropdown-type="sort"]:before {
        content: 'Sortowanie';
    }

    &[data-dropdown-type="pageSize"] {

        &:before {
            content: 'Ilość';
        }

        .dropdown-button {
            min-width: 207px;
        }
    }
}

.dropdown-button {
    border: 1px solid @border-color;
    color: @font-color;
    min-width: 337px;
    height: 48px;
    text-align: left;
    position: relative;
    padding: 0 62px 0 20px;
    z-index: 1;
    border-radius: 4px;
    font-size: 14px;

    &:after {
        content: "\e91a";
        font-family: icomoon;
        position: absolute;
        top: 0;
        right: 3px;
        width: 48px;
        height: 48px;
        color: @header-color;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        border-color: @header-color;
    }
}

.dropdown-listing {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: @total-color;
    border: 1px solid @border-color;
    border-top: 0;
    .shadow();
    min-width: 100%;
    padding: 5px 0;
    transform: translateY(-5px);
    transition: all .2s ease-in-out;
    border-radius: 4px;

    span {
        display: block;
    }

    input {
        display: none;

        &:checked + label {
            font-weight: 700;
            color: @header-color;
            text-decoration: none !important
        }
    }

    label {
        display: block;
        padding: 5px 17px;
        user-select: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

// IE FALLBACK
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .sorter-button:before {
        display: none;
    }
}